<template>
	<DataTable
		:getQuery="queries.get"
		:createQuery="queries.create"
		:removeQuery="queries.remove"
		:updateQuery="queries.update"
		:subscriptionQuery="queries.subscribe"
		:createFields="createFields"
		:headers="headers"
		:hide_add_button="true"
		icon="mdi-cart"
		result_name="marketRequests"
		title="market requests"
		subtitle="List of all market requests"
	>
	</DataTable>
</template>
<script>
import DataTable from '@/components/datatable'

export default {
	components: {DataTable},
    data(){
		return {
			queries: require('@/gql/marketRequest.gql'),
			createFields: [
				{ name:'status', type:"select", label: 'Status', icon: 'mdi-av-timer',
					items: ['pending', 'awaiting pickup', 'on route', 'failed', 'success']
				},
			],
			headers: [
				{ text: 'Customer Name', align: 'left', value: 'customer.first_name' },
				{ text: 'Phone No.', align: 'left', value: 'phone', sortable: false},
				{ text: 'Cost', align: 'left', value: 'cost', sortable: false },
				{ text: 'Status', align: 'center', value: 'status' },
				{ text: 'Payment', align: 'center', value: 'transaction.final_amount' },
				{ text: 'Items', align: 'center', value: 'button', button: {
					label: 'view',
					icon: 'mdi-cart',
					color: 'primary',
					text: true,
					show_length: 'products',
					rounded: true,
					to: {
						name: 'admin-view-customer-products',
						params: { key: 'products', value: 'products'},
						self: true
					} 
				},
					sortable: false
				},	
				{ text: 'Request Date', align: 'left', value: 'createdAt'},
				{ text: 'Request Time', align: 'left', value: 'createdAt', time: true},
				{ text: 'Actions', value: 'action', sortable: false },
			],
		}
    },
}
</script>